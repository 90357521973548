.style-nav-0 {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 80px;
  z-index: 91;
}

.style-nav-1 {
  box-sizing: border-box;
  position: relative;
  display: block;
}

.style-nav-2 {
  box-sizing: border-box;
  position: static;
  display: block;
  float: left;
}

.style-nav-3 {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: left;
  padding: 38.5px 0px;
  margin-right: 40px;
}

.style-nav-4 {
  box-shadow: none;
  text-decoration: none solid rgb(119, 113, 129);
  box-sizing: border-box;
  color: rgb(119, 113, 129);
}

.style-nav-5 {
  height: auto;
  max-width: 100%;
  border: 0px none rgb(119, 113, 129);
  border-radius: 0px;
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  image-rendering: -webkit-optimize-contrast;
  backface-visibility: hidden;
  transform: matrix(1, 0, 0, 1, 0, 0);
  vertical-align: middle;
}

.style-nav-6 {
  height: auto;
  max-width: 100%;
  border: 0px none rgb(119, 113, 129);
  border-radius: 0px;
  box-shadow: none;
  box-sizing: border-box;
  display: none;
  image-rendering: -webkit-optimize-contrast;
  backface-visibility: hidden;
  transform: none;
  vertical-align: middle;
}

.style-nav-7 {
  box-sizing: border-box;
  position: static;
  display: block;
  float: left;
}

.style-nav-8 {
  display: none;
  box-shadow: none;
  text-decoration: none solid rgb(102, 83, 232);
  box-sizing: border-box;
  font-size: 20px;
  color: rgb(102, 83, 232);
  cursor: pointer;
  transition: all 0.5s ease 0s;
}

.style-nav-9 {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 20px;
  box-sizing: border-box;
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
}

.style-nav-10 {
  box-sizing: border-box;
}

.style-nav-11 {
  display: flex;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  list-style-nav-type: none;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  list-style: outside none none;
  padding-left: 0px;
}

.style-nav-12 {
  box-sizing: border-box;
  padding-top: 42px;
  padding-bottom: 42px;
  position: relative;
}

.style-nav-13 {
  box-shadow: none;
  text-decoration: none solid rgb(27, 21, 37);
  box-sizing: border-box;
  color: rgb(27, 21, 37);
  font-size: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  transition: all 0.5s ease 0s;
  font-weight: 400;
}

.style-nav-14 {
  box-sizing: border-box;
  margin-left: 60px;
  padding-top: 42px;
  padding-bottom: 42px;
  position: relative;
}

.style-nav-15 {
  box-shadow: none;
  text-decoration: none solid rgb(119, 113, 129);
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(119, 113, 129);
  position: relative;
  transition: all 0.5s ease 0s;
  font-weight: 400;
}

.style-nav-16 {
  box-sizing: border-box;
  margin-left: 60px;
  padding-top: 42px;
  padding-bottom: 42px;
  position: relative;
}

.style-nav-17 {
  box-shadow: none;
  text-decoration: none solid rgb(119, 113, 129);
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(119, 113, 129);
  position: relative;
  transition: all 0.5s ease 0s;
  font-weight: 400;
}

.style-nav-18 {
  box-sizing: border-box;
  margin-left: 60px;
  padding-top: 42px;
  padding-bottom: 42px;
  position: relative;
}

.style-nav-19 {
  box-shadow: none;
  text-decoration: none solid rgb(119, 113, 129);
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(119, 113, 129);
  position: relative;
  transition: all 0.5s ease 0s;
  font-weight: 400;
}

.style-nav-20 {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: right;
  padding: 35px 0px;
}

.style-nav-21 {
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.style-nav-22 {
  display: none;
  box-sizing: border-box;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
}

.style-nav-23 {
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  font-family: icomoon;
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.style-nav-24 {
  height: auto;
  max-width: 100%;
  border: 0px none rgb(119, 113, 129);
  border-radius: 0px;
  box-shadow: none;
  box-sizing: border-box;
  width: auto;
  vertical-align: middle;
}

.style-nav-25 {
  box-sizing: border-box;
  margin-left: 15px;
}

.style-nav-26 {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 27px;
  margin: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.style-nav-27 {
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  color: rgb(27, 21, 37);
  margin: 0px;
  clear: both;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 19.2px;
}

.style-nav-28 {
  box-shadow: none;
  text-decoration: none solid rgb(27, 21, 37);
  box-sizing: border-box;
  color: rgb(27, 21, 37);
  transition: all 0.5s ease 0s;
}