@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

* { font-family: "Kumbh Sans", sans-serif; }

body, #root {
  margin: 0;
  width: 100%;
  height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

header {
  position: fixed;
  background-color: white;
  width: 100%;
  border-bottom: solid #333 1px;
  z-index: 2;
}

main {
  width: 100%;
  margin-top: clamp(200px, 10vh, 300px);
  padding: 16px 0px 80px 0px;
  flex-grow: 1;
}

p,
h4,
h5 {
    color: #777181;
    font-size:16px;
    line-height:30px;
}

h4,
h5{
    margin:0;
    font-weight:700;
}

h4 {
    color:#1b1525;
}

h5 {
    font-size:18px;
    line-height:28px;
}

a,
a:visited {
    color:#6666FF !important;
}

header a {
    color:#777181 !important;
}

header i.material-icons {
    font-size:16px;
    color:#2BE4AC;
}

.error-message {
  margin-bottom: 8px;
  background-color: mistyrose;
  padding: 4px;
  color: #d01918;
}

/**  INPUTS */

input[type=text], input[type=email], input[type=url] {
  display: block;
  border: none;
  outline: none;
  background-color: #f7f7f9;
  color: #777181;
  padding-left: 20px;
  height: 50px;
  width: 100%;
  padding-right: 50px;
  font-weight: bolder;
}

input::placeholder {
  color: #aaa;
  font-weight: bold;
}

.pricing__btn {
  padding: 14px 40px;
  /* width: 100%; */
  text-align: center;
  color: white;
  font-size:16px;
  font-weight:700;
  background-color: #2BE4AC;
  border: 1px solid #2BE4AC;
}
.thm-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none !important;
  /*background-color: #ffffff;
  color: var(--notech-white, #00edb9);
  font-size: 16px;
  font-weight: 700;*/
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  overflow: hidden;
  cursor: pointer;
}
.thm-btn:disabled {
  color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  background-color:white;
}

/**  CHECKBOX */


  .checkbox-wrapper-49 input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }

  .checkbox-wrapper-49 {
    --size: 60px;
  }

  .checkbox-wrapper-49 .block {
    position: relative;
    clear: both;
    float: left;
  }

  .checkbox-wrapper-49 label {
    width: var(--size);
    height: 26px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    background-color:#f7f7f9;
    float: left;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    transition: .3s ease;
    color: black;
  }
  .checkbox-wrapper-49 input[type=checkbox]:checked + label {
    background: #00edb9;
    border-color: #2BE4AC;
  }
  .checkbox-wrapper-49 input[type=checkbox]:checked + label:before {
    left: calc(var(--size) / 2 + 6px);
    background-color:white;
  }
  .checkbox-wrapper-49 label:before {
    transition: .3s ease;
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background: #666;
    left: 2px;
    top: 2px;
    vertical-align:middle;
    box-sizing: border-box;
    color: black;
    border-radius: 100px;
  }

/** recaptcha */
.grecaptcha-badge { 
    visibility: hidden;
}

#captcha-branding {
  font-size: small;
  color: #bbb;
}

#captcha-branding > a { color: #85c1f4; }
