.style-top-hdr-0 {
  height: 40px;
  box-sizing: border-box;
  position: relative;
  display: block;
  padding: 4px 80px;
  border-bottom: 0.666667px solid rgb(233, 233, 238);
}

.style-top-hdr-1 {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: left;
}

.style-top-hdr-2 {
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  list-style: outside none none;
  margin: 0px;
  padding-left: 0px;
}

.style-top-hdr-3 {
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.style-top-hdr-4 {
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  font-family: icomoon;
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.style-top-hdr-5 {
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 12px;
  -webkit-font-smoothing: antialiased;
  font-family: icomoon;
  box-sizing: border-box;
  font-size: 12px;
  color: rgb(102, 83, 232);
}

.style-top-hdr-6 {
  box-sizing: border-box;
  margin-left: 10px;
}

.style-top-hdr-7 {
  box-sizing: border-box;
  font-size: 12px;
  line-height: 34px;
  margin: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.style-top-hdr-8 {
  box-sizing: border-box;
  margin-left: 40px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.style-top-hdr-9 {
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  font-family: icomoon;
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.style-top-hdr-10 {
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 12px;
  -webkit-font-smoothing: antialiased;
  font-family: icomoon;
  box-sizing: border-box;
  font-size: 12px;
  color: rgb(102, 83, 232);
}

.style-top-hdr-11 {
  box-sizing: border-box;
  margin-left: 10px;
}

.style-top-hdr-12 {
  box-sizing: border-box;
  font-size: 12px;
  line-height: 34px;
  margin: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.style-top-hdr-13 {
  box-shadow: none;
  text-decoration: none solid rgb(119, 113, 129);
  box-sizing: border-box;
  color: rgb(119, 113, 129);
  transition: all 0.5s ease 0s;
}

.style-top-hdr-14 {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: right;
}

.style-top-hdr-15 {
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.style-top-hdr-16 {
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 40px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  list-style: outside none none;
  margin: 0px 40px 0px 0px;
  padding-left: 0px;
}

.style-top-hdr-17 {
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 40px;
  z-index: 1;
}