#takedown-page {
  display: flex;
  flex-direction: column;
  align-items: center;  
}

#takedown-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  margin-bottom: 2px;
}

button {
  margin-top: 8px;
  cursor: pointer;
}