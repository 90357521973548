#sign-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

object {
  height: 70%;
  width: 90%;
  pointer-events: none;
}