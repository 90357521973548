#confirmation-form, form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.terms-and-conditions-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

textarea {
  display: block;
  border: none;
  outline: none;
  width: 100%;
  background-color: #f7f7f9;
  color: #777181;
  padding-left: 20px;
  padding-right: 50px;
}

textarea::placeholder {
  color: #bbb;
}

button {
  margin-top: 24px;
}

.form-label {
  color: #777181;
}
