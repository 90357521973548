@page {
  size: A4 portrait;
}

@media print {
  @page {
    size: A4 portrait;
  }
}

.takedown-mandate {
  margin: 0 auto;
  padding: 60px;
  font-family: 'Roboto Condensed', sans-serif;
  width: 800px;
  border: solid 1px #444;
}

.takedown-mandate #mandate-header {
  text-align: right;
  padding-top: 12pt;
}

.takedown-mandate #mandate-header > img {
  margin-top: 20px;
  width: 250px;
}

.takedown-mandate > main {
  margin-top: 0;
}

.takedown-mandate main > * {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.takedown-mandate #mandate-title {
  margin: 0 auto;
  text-align: center;
}

.takedown-mandate #mandate-body * {
  align-self: flex-start;
  font-size: 13pt;
  height: fit-content;
}

.takedown-mandate #signature-image {
  width: 250px;
  height: 100px;
}

.takedown-mandate #mandate-footer {
  margin: 0;
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .takedown-mandate {
    margin: auto;
    width: 70%;
  }
}